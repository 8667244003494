.commonBorder {
    background: #0062be;
    height: 3px;
    width: 100px;
    margin: 30px auto;
}

.skills__container {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 0fr);
    grid-gap: 0px;
    align-items: center;
    position: relative;
    height: auto;
}

.skills__box {
    padding: 40px;
    background: rgb(34, 34, 34);
    color: #fff;
    margin: 10px;
    transition: all 0.5s linear;
    width: 200px;
    text-align: center;
    position: relative;
}
.skills__box:hover {
    background: #0062be;
}
.commonIcons {
    font-size: 50px;
    color: #0062be;
    margin-bottom: 15px;
}
.skills__box:hover .commonIcons {
    color: #fff;
}
.skills__box-header {
    font-size: 16px;
    font-weight: 700;
}
.skills__box-p {
    font-size: 14px;
    color: #dfdfdf;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Media Queries for Tablets*/
@media screen and (max-width: 1024px) {
    .skills__container {
        grid-template-columns: repeat(3, 0fr);
    }
}

/* Media Queries for Phones*/
@media screen and (max-width: 600px) {
    .skills__container {
        grid-template-columns: repeat(2, 0fr);
    }
}
