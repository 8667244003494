.commonBorder {
    background: #0062be;
    height: 3px;
    width: 100px;
    margin: 30px auto;
}

.about__container {
    display: grid;
    justify-content: center;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 20px;
    position: relative;
}

.myPhoto {
    width: 22rem;
    height: 30rem;
    position: relative;
    left: calc(50% - 11rem);
    margin-top: 4rem;
}

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
}

@media screen and (max-width: 600px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
}
