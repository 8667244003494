@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;700&display=swap');
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-blue: #0062be;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Golos Text', sans-serif;
    background: black;
    color: white;
    line-height: 1.7;
    /*Background
    background-image (replace with Background Component)
    */
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    padding-top: 35px;
}

section > h2,
section > h5 {
    text-align: center;
}

section > h5 {
    color: var(--color-blue);
}

section > h2 {
    margin-bottom: 0rem;
    color: white;
}

.test-light {
    color: white;
}

a {
    color: white;
    transition: all 400ms ease;
}

a:hover {
    color: white;
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-blue);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-blue);
    transition: all 400ms ease;
    background: var(--color-blue);
    color: white;
}

.btn:hover {
    background: white;
    color: var(--color-blue);
    border-color: transparent;
}

.btn-primary {
    background: white;
    color: var(--color-blue);
}

.btn-primary:hover {
    background: var(--color-blue);
    color: white;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* Media Queries for Tablets*/
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* Media Queries for Phones*/
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}
