footer {
    background-color: transparent;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    margin-top: 50px;
    color: white;
}

p {
    margin: 0;
}
