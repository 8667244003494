.education-experience-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabs-container {
    border: 1px solid white;
    width: 50%;
    margin-bottom: 1rem;
}

.tabs {
    display: flex;
    justify-content: space-around;
}

button {
    border: none;
    background-color: transparent;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem;
    transition: all 0.3s ease-in-out;
}

button:hover,
button.active {
    background-color: white;
    color: black;
    border-radius: 10px;
}

.tab__content {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 50%; */
    /* height: 200px; */
    background-color: transparent;
    color: black;
    border-radius: 10px;
    padding: 1rem;
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
    color: white;
}

.tab-pane-ubc > h3 {
    color: var(--color-blue);
    font-style: italic;
}

.tab-pane-bcit > h3 {
    color: var(--color-blue);
    font-style: italic;
}

.tab-pane-bcaa > h3 {
    color: var(--color-blue);
    font-style: italic;
}

.tab-pane-oooh > h3 {
    color: var(--color-blue);
    font-style: italic;
}

.education__content {
    justify-content: center;
    background-color: transparent;
    color: black;
    border-radius: 10px;
    padding: 1rem;
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: 10%;
    gap: 10%;
    color: white;
}

.tab-pane-ubc {
    text-align: center;
}

.tab-pane-bcit {
    text-align: center;
}

.experience__content {
    justify-content: center;
    background-color: transparent;
    color: black;
    border-radius: 10px;
    padding: 1rem;
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-left: 10%;
    gap: 10%;
    color: white;
}

.tab-btn {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px;
    margin-right: 10px;
}

.tab-btn.active {
    border-bottom: 2px solid white;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 600px) {
    .tabs-container {
        width: 70%;
    }

    .education__content {
        grid-template-columns: 100%;
        gap: 1.5rem;
    }

    .tab-pane-ubc > h4,
    h5,
    h6 {
        font-size: 0.8rem;
    }

    .experience__content {
        grid-template-columns: 100%;
        gap: 1.5rem;
    }
}
