.commonBorder {
    background: #0062be;
    height: 3px;
    width: 100px;
    margin: 30px auto;
}

.projects__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    position: relative;
    height: auto;
}

.project__item {
    background: rgb(34, 34, 34);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.project__item:hover {
    border-color: white;
    background: gray;
}

.project__item_img {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 160px;
}

.languages__tools {
    font-size: 0.8rem;
}

.project__item h3 {
    margin: 1.2rem 0 1rem;
    color: var(--color-blue);
}

.project__item_cta {
    display: flex;
    gap: 2rem;
    justify-content: center;
    text-align: center;
}

/* Media Queries for Tablets*/
@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
    }
}

/* Media Queries for Phones*/
@media screen and (max-width: 600px) {
    .projects__container {
        position: relative;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
