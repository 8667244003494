.website__name {
    display: inline-block;
    width: 50%;
    position: left;
    padding-top: 0px;
    margin-left: 20px;
    vertical-align: top;
    color: var(--color-blue);
}

nav {
    background-color: rgba(255, 255, 255, 0);
    position: fixed;
    /* left: 75%; */
    /* height: 50px; */
    display: inline-block;
    /* margin-left: 90px; */
    right: 0;
    padding-top: 10px;
}

.navbar-closed {
    /* padding-top: 10px; */
}

.nav__list a {
    margin-left: 1.5em;
    margin-right: 1.3em;
}

nav a:hover {
    /* background: rgb(65, 147, 240); */
    color: var(--color-blue);
}

nav a.active {
    /* background: rgb(65, 147, 240); */
    color: var(--color-blue);
    font-weight: bold;
    position: relative;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 600px) {
    .website__name {
        display: inline-block;
        width: 50%;
        position: left;
        padding-top: 20px;
        margin-left: 20px;
    }

    .nav__list {
        display: none;
        background-color: white;
        position: absolute;
        margin-top: 10px;
        z-index: 1;
        right: 0;
        top: 50px;
    }

    .nav__list--open {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav__list a {
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        color: #333;
        text-align: center;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
    }

    .nav__list a:hover {
        color: #007bff;
    }

    .navbar-toggle {
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
        background-color: transparent;
        padding-top: 15px;
    }

    .navbar-toggle-icon {
        display: block;
        width: 2rem;
        height: 2px;
        background-color: white;
        margin: 0.5rem;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-toggle-icon.open {
        transform: rotate(90deg);
        background-color: #333;
    }

    .navbar-toggle-icon:before,
    .navbar-toggle-icon:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-toggle-icon.open:before,
    .navbar-toggle-icon.open:after {
        background-color: #333;
    }

    .navbar-toggle-icon:before {
        transform: translateY(-0.5rem);
    }

    .navbar-toggle-icon:after {
        transform: translateY(0.5rem);
    }
}
